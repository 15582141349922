import React, { useCallback, useContext } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import block from "bem-css-modules";

import {
  getMinSubscriptionPrice,
  getTrialDay,
  getCtaUrl,
} from "../../helpers/common";
import Logo from "../Logo";
// import SearchField from '../SearchField';
import Hamburger from "../Hamburger";
import { GTMCTAClicked } from "../../helpers/gtm";

import heroBanner from "../../styles/components/Herobanner.module.scss";
// import button from '../../styles/components/Button.module.scss';
import { t } from "../../helpers/common";
import { AppContext } from "../../helpers/AppContext";

function HeroBanner({
  subscriptions,
  subscriptionInfo,
  userDetails,
  signupBook,
  authKeys,
  openSuggest,
  post,
}) {
  const { locale } = useRouter();

  block.setSettings({
    modifierDelimiter: "--",
  });

  const hero = block(heroBanner);

  let cssLocaleCode = {};
  cssLocaleCode[`${locale}`] = true;

  return (
    <div
      className={`${hero("banner")} `}
      data-label={post?.SideTitle || ""}
      alt={"image"}
      id={"Herobanner_banner_id"}
    >
      <header className={hero("header", { flex: true })}>
        <div className={hero("headerHamburger")}>
          <div className={hero("area")}>
            <Logo />
          </div>
          <div className={hero("area")}>
            <Hamburger
              authKeys={authKeys}
              subscriptions={subscriptions}
              subscriptionInfo={subscriptionInfo}
              userDetails={userDetails}
              signupBook={signupBook}
              noBackground={"bg"}
              openSuggest={openSuggest}
            />
          </div>
        </div>

        <div className={hero("headerText")}>
          <h1 className={hero("head")} id="hp_txt_ban_head">
            <span className={hero("headerTextLabel")}>
              {post?.SideTitle || ""}
            </span>
            {post?.Title || ""}
          </h1>
          <div className={hero("para")} id="hp_txt_sub_head">
            <p className={hero("paraText")}> {post?.SubTitle || ""} </p>
            <a href={post?.LinkURL || ""} className={hero("link")}>
              {post?.LinkText || ""}
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeroBanner;
